import {
  ref, onMounted, getCurrentInstance
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { isNumber } from 'lodash'
import { useStore } from 'vuex'
import { groupUserUseCase, reportSettingUserGroupUseCase } from '@/domain/usecase'

export default {
  setup() {
    const app = getCurrentInstance()
    const { $toast, $strInd, $confirm } = app!.appContext.config.globalProperties
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const data = ref([]) as any
    const listGroup = ref([]) as any

    const getAllData = async () => {
      const response = await reportSettingUserGroupUseCase.getAll({
        id: route.params.id
      })
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
        router.go(-1)
      } else {
        const options = response.result.map((d: any) => d.Group.Id)
        data.value = options
      }
    }

    const getListGroup = async () => {
      await getAllData()
      const response = await groupUserUseCase.getAll()
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
        router.go(-1)
      } else {
        console.log('res', response.result)
        const options = response.result.Data.map((d: any) => {
          d.label = d.Nama
          d.value = d.Id
          d.IsActive = isNumber(data.value.find((element: number) => element === d.Id))
          return d
        })
        listGroup.value = options
      }
    }

    const redirectBack = () => {
      router.go(-1)
    }
    const submitProcess = async () => {
      store.dispatch('showLoading')
      const dataForm = {
        ReportId: route.params.id,
        GroupIds: data.value,
      }
      const response = await reportSettingUserGroupUseCase.submitData(dataForm)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorSubmit, group: 'bc', life: 3000
        })
      } else {
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })
        redirectBack()
      }
      store.dispatch('hideLoading')
    }
    const submitData = () => {
      submitProcess()
    }
    const activateAct = async (val: any) => {
      store.dispatch('showLoading')
      const dataNewVal = []
      for (let index = 0; index < listGroup.value.length; index++) {
        const element = listGroup.value[index]
        if (element.IsActive) {
          dataNewVal.push(element.Id)
        }
      }
      data.value = dataNewVal
      const dataForm = {
        ReportId: route.params.id,
        GroupIds: data.value,
      }
      const response = await reportSettingUserGroupUseCase.submitData(dataForm)
      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorChangeStatus, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: response.result.detail ?? response.result.Detail, group: 'bc', life: 1500
        })
      }
      store.dispatch('hideLoading')
    }
    const changeIsActive = async (val: any) => {
      $confirm.require({
        header: 'Status Aktif',
        message: $strInd.confirm.msgChange,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          activateAct(val)
        },
        reject: () => {
          val.IsActive = !val.IsActive
        }
      })
    }

    onMounted(() => {
      // getAllData()
      getListGroup()
    })

    return {
      data,
      listGroup,
      redirectBack,
      submitData,
      changeIsActive
    }
  }
}
